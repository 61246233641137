<template>
  <div
    class="ps__wrapper section-padding"
    :style="{
      backgroundImage: `url(${backgroundImage})`,
    }"
  >
    <div class="container default-padding ">
      <div class="primary-heading  mb-4">
        Our Products
      </div>

      <div class="ps__items">
        <router-link
          v-for="product in productList"
          :key="product.id"
          :to="{ name: 'Products' }"
          custom
          v-slot="{ navigate }"
          data-aos="fade-up"
        >
          <div @click="navigate" role="link " class="ps__item">
            <img
              :src="product.image_url ? product.image_url : placeholder"
              alt="Product and Services"
              class="image"
            />
            <div class="description">
              <div class="primary-heading primary-heading--sm">
                {{ product.name }}
              </div>
              <div
                class="text-center paragraph"
                v-html="product.description"
              ></div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import backgroundImage from "@/assets/images/defaults/products-and-services__background.jpg";
  import placeholder from "@/assets/images/defaults/transformer.jpg";
  import { mapState } from "vuex";

  export default {
    name: "HomeProducts",

    data() {
      return {
        backgroundImage,
        placeholder,
        services: [
          {
            title: "Distribution Transformer",
            description:
              "Allied Power Filled Distribution Transformers are supplied both in Stacked Core & Wound Core versions from 10 KVA to 2500 KVA rating transformers with robust design and sturdy construction.",
            image: "",
          },
          {
            title: "Power Transformer",
            description:
              "The company is also manufacturing 33/11 KV Power Transformers upto 10 MVA ratings as per requirement of the customer. All transformers are manufactured with imported CRGO Silicon Steel",
            image: "",
          },
          {
            title: "Over Hauling",
            description:
              "Allied Power Filled Distribution Transformers are supplied both in Stacked Core & Wound Core versions from 10 KVA to 2500 KVA rating transformers with robust design and sturdy construction.",
            image: "",
          },
          {
            title: "Repair",
            description:
              "The company is also manufacturing 33/11 KV Power Transformers upto 10 MVA ratings as per requirement of the customer. All transformers are manufactured with imported CRGO Silicon Steel",
            image: "",
          },
        ],
      };
    },

    computed: {
      ...mapState({ productList: (state) => state.product.products.data }),
    },

    created() {
      this.loadProducts();
    },

    methods: {
      loadProducts() {
        this.$store.dispatch("product/getAllProducts");
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .ps__wrapper {
    position: relative;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &:before {
      position: absolute;
      inset: 0;
      background-color: rgba($primary-color, 0.2);
      backdrop-filter: blur(6px);
      content: "";
    }
  }

  .ps {
    &__items {
      opacity: 0.9;
    }

    &__item {
      margin-bottom: 1.5rem;

      box-sizing: border-box;

      &:hover {
        cursor: pointer;
      }

      .image,
      .description {
        height: 300px;
        overflow: hidden;
      }

      .description {
        background-color: white;
        padding: 1.5rem 4rem;
        box-sizing: border-box;
        opacity: 0.9;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .paragraph {
          @include line-clamp(4);
        }
      }

      .image {
        float: right;
        // margin-left: 1rem;
        width: 50%;
      }

      &:nth-child(odd) {
        .image {
          float: left;
          //   margin-right: 1rem;
          //   margin-left: 0;
        }
      }
    }
  }

  @media only screen and (max-device-width: 768px) {
    .ps__item {
      .image {
        float: none;
        width: 100%;
      }

      .description {
        padding: 1rem;
        box-sizing: content-box;
      }
    }
  }
</style>
