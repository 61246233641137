<template>
  <div class="card">
    <div class="card__content">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped>
  .card {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 0.25rem;

    &__content {
      padding: 0.55rem;
      z-index: 1;
      height: 100%;
      flex: 1;
      flex-direction: column;
    }

    &::before {
      content: "";
      position: absolute;
      width: 80px;
      height: 140%;
      background: linear-gradient(red, black);
      transform: rotate(-40deg);
    }

    &::after {
      content: "";
      position: absolute;
      inset: 4px;
      background: white;
      border-radius: 0.25rem;
    }

    &:hover {
      &::before {
        width: 200px;
        animation: animate 4s linear infinite;
      }
    }
  }

  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
