<template>
  <div class="about-us__wrapper">
    <div
      class="about-us__banner"
      :style="{
        backgroundImage: `url(${require('@/assets/images/defaults/products-and-services__background.jpg')})`,
      }"
      data-aos="slide-right"
    ></div>
    <div class="container">
      <div class="about-us__content ">
        <div style="padding : 5rem 2rem">
          <div data-aos="slide-up">
            <div class="primary-heading">{{ aboutContent.title }}</div>

            <div class="paragraph" v-html="aboutContent.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "AboutUs",

    computed: {
      ...mapState({
        allContents: (state) => state.editableContent.editableContents.data,
      }),

      aboutContent() {
        return (
          this.allContents &&
          this.allContents.find((c) => c.title.toLowerCase() == "about us")
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .about-us {
    &__wrapper {
      position: relative;
    }

    &__banner {
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    &__content {
      width: 50%;
      margin-left: auto;
      background-color: white;
    }
  }

  @media only screen and (max-device-width: 768px) {
    .about-us {
      &__banner {
        display: none;
      }
      &__content {
        width: 100%;
      }
    }
  }
</style>
