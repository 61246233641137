const data = [
  {
    name: "Debra Harris",
    photo: "person-1.jpg",
    position: "Founder & CEO",
    company: "Nepal Electricity Autority"
  },
  {
    name: "Terrance Barnett",
    photo: "person-2.jpg",
    position: "Vice President",
    company: "Neek"
  },
  {
    name: "Sarah Conor",
    photo: "person-3.jpg",
    position: "General Secretary",
    company: "Neek"
  },
  {
    name: "Jean Vargas",
    photo: "person-4.jpg",
    position: "Treasurer",
    company: "Neek"
  },
  {
    name: "Jean Walton",
    photo: "person-5.jpg",
    position: "Manager",
    company: "Butwal Saw Mill"
  },
  {
    name: "Haleey Reed",
    photo: "person-6.jpg",
    position: "Marketting Director",
    company: "Gorkha Lahari Pvt. Ltd."
  },
];

export default data;
