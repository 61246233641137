<template>
  <content-wrapper title="Dashboard">
    It's a me! Dashboard.
  </content-wrapper>
</template>

<script>
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";

  export default {
    name: "Dashboard",

    components: {
      ContentWrapper,
    },
  };
</script>

<style></style>
