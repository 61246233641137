<template>
  <div
    class="wrapper section-padding"
    :style="{
      backgroundImage: `url(${backgroundImage})`,
    }"
  >
    <div class="container default-padding">
      <div data-aos="fade-up">
        <div class="primary-heading">Services</div>
        <div class="caption mb-8">
          We are the industry heads and produce the most reliable and
          trendy solution you are looking for.
        </div>
      </div>

      <el-row :gutter="30">
        <el-col :span="24" :lg="12" class="mb-4">
          <div
            v-for="{ id, name } in serviceList"
            :key="id"
            class="mb-3"
            data-aos="fade-left"
          >
            <span>✔️</span> {{ name }}
          </div>
        </el-col>
        <el-col :span="24" :lg="12">
          <div class="quote" data-aos="fade-left">
            <div class="mb-3">
              The only place where you'll get the perfect solution for all your
              industry needs
            </div>
            <router-link to="/contact-us" class="button">
              Get A Quote
            </router-link>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import backgroundImage from "@/assets/images/defaults/products-and-services__background.jpg";

  import { mapState } from "vuex";

  export default {
    name: "HomeServices",

    data() {
      return {
        backgroundImage,
      };
    },

    computed: {
      ...mapState({
        serviceList: (state) => state.service.services.data,
      }),
    },

    created() {
      this.loadServices();
    },

    methods: {
      loadServices() {
        this.$store.dispatch("service/getAllServices");
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .wrapper {
    position: relative;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &:before {
      position: absolute;
      inset: 0;
      background-color: rgba($background-color, 0.4);
      backdrop-filter: blur(6px);
      content: "";
    }
  }

  .quote {
    height: 100%;
    .button {
      border: 1px solid red;
      cursor: pointer;
      display: inline-block;
      padding: 0.75rem 2rem;
      border-radius: 2rem;

      transition: all 200ms linear;

      &:hover {
        background: $primary-color;
        color: white;
      }
    }
  }

  // .service {
  //   position: relative;

  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: center;

  //   height: 200px;
  //   margin-bottom: 2rem;

  //   display: flex;
  //   justify-content: center;
  //   align-items: center;

  //   overflow: hidden;
  //   z-index: -1;

  //   &::after {
  //     content: "";
  //     position: absolute;
  //     inset: 0;
  //     //   background-color: rgba($primary-color, 0.1);
  //     content: "";
  //     z-index: -1;
  //   }

  //   &__title {
  //     padding: 1rem 2rem;
  //     backdrop-filter: blur(3px);
  //     background-color: rgba(#fff, 0.3);
  //   }
  // }
</style>
