const userInterval = function(fn, t) {
  let timerObj = setInterval(fn, t);

  let stop = function() {
    if (timerObj) {
      clearInterval(timerObj);
      timerObj = null;
    }
    return this;
  };

  // start timer using current settings (if it's not already running)
  let start = function() {
    if (!timerObj) {
      stop();
      timerObj = setInterval(fn, t);
    }
  };

  // start with new or original interval, stop current interval
  let reset = function(newT = t) {
    t = newT;
    stop();
    start();
  };

  return { stop, start, reset };
};

export default userInterval;
