<template>
  <div class="wrapper">
    <div class="slider my-4" :style="{ height: `${sliderHeight}px` }">
      <div class="slider__nav" @click="decrementIndex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          class="bi bi-chevron-compact-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
          />
        </svg>
      </div>

      <transition-group>
        <div v-for="(slide, index) in sliderItems" :key="index">
          <div v-if="index === currentIndex" class="slider__content">
            <slot :slide="slide" />
          </div>
        </div>
      </transition-group>

      <div class="slider__nav" @click="incrementIndex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          class="bi bi-chevron-compact-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
          />
        </svg>
      </div>
    </div>

    <div v-if="!hideDelimeters" class="delimeters">
      <div
        v-for="(items, index) in sliderItems"
        :key="index"
        class="delimeter"
        :class="index === currentIndex && 'delimeter--current'"
        @click="changeIndex(index)"
      ></div>
    </div>
  </div>
</template>

<script>
  import useInterval from "@/composables/useInterval";

  export default {
    name: "Slider",

    props: {
      slides: { type: Array, required: true },
      sliderHeight: { type: [String, Number], default: 200 },
      hideDelimeters: { type: Boolean, default: false },
    },

    data() {
      return {
        sliderItems: [...this.slides],
        currentIndex: 0,
        interval: 70000,
        timeInterval: useInterval(this.incrementIndex, this.interval),
      };
    },

    mounted() {
      this.startSlideShow();
    },

    destroyed() {
      this.timeInterval.stop();
    },

    methods: {
      incrementIndex() {
        if (this.currentIndex === this.sliderItems.length - 1)
          this.currentIndex = 0;
        else this.currentIndex++;
        this.timeInterval.reset(this.interval);
      },

      decrementIndex() {
        if (this.currentIndex === 0)
          this.currentIndex = this.sliderItems.length - 1;
        else this.currentIndex--;
        this.timeInterval.reset(this.interval);
      },

      startSlideShow() {
        this.timeInterval.start();
      },

      changeIndex(index) {
        this.currentIndex = index;
        this.timeInterval.reset(this.interval);
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/scss/main";

  .wrapper {
    width: 100%;
  }

  .slider {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    &__nav {
      cursor: pointer;
      border-radius: 50%;
      padding: 0.25rem 0.6rem;
      background-color: rgba($background-color, $alpha: 0.2);

      &:hover {
        background-color: rgba($background-color, $alpha: 0.6);
      }

      svg {
        height: 30px;
        width: 30px;
        font-weight: 800;
        margin-top: 0.25rem;
      }
    }

    &__content {
      width: 60vw;
      flex-grow: 1;
    }
  }

  .delimeters {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    & > * {
      margin-right: 0.5rem;
    }

    .delimeter {
      background: lightgray;
      height: 0.75rem;
      width: 0.75rem;
      padding: margin;

      border-radius: 50%;
      // cursor: pointer;

      transition: all 300ms linear;

      &--current {
        width: 2rem;
        background: $primary-color;
        border-radius: 2rem;
      }
    }
  }

  @media only screen and (max-device-width: 768px) {
    .slider {
      height: 100% !important;
      &__nav {
        display: none;
      }

      &__content {
        margin: 0;
        padding: 0;
        width: 100%;
      }
    }
  }
</style>
