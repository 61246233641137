<template>
  <div class="container default-padding">
    <div class="section-padding counter">
      <div class="counter__card" data-aos="slide-up">
        <div class="counter__text">
          Number Speaks For Themselves
        </div>
      </div>
      <div class="counter__card" data-aos="slide-up" data-aos-offset="100">
        <div class="count">
          <div class="number">{{ company.years_experienced }}</div>
          <!-- <span class="subtext">Years</span> -->
        </div>
        <div class="title">Years of Experience</div>
      </div>
      <div class="counter__card" data-aos="slide-up" data-aos-offset="200">
        <div class="count">
          <div class="number">{{ company.industries_served }}</div>
          <!-- <span class="subtext">Years</span> -->
        </div>
        <div class="title">Industries Served</div>
      </div>
      <div class="counter__card" data-aos="slide-up" data-aos-offset="300">
        <div class="count">
          <div class="number">{{ company.factories_built }}</div>
          <!-- <span class="subtext">Years</span> -->
        </div>
        <div class="title">Factories Built</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "Counter",

    data() {
      return {
        count: 0,
      };
    },

    computed: {
      ...mapState({ company: (state) => state.organization.orgDetail }),
    },
  };
</script>

<style lang="scss" scoped>
  .counter {
    width: 100%;
    margin: 0 auto;

    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-gap: 20px;

    &__text {
      font-size: 2.2rem;
      font-weight: 500;
    }

    &__card {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .count {
        display: flex;
        align-items: baseline;
        .number {
          font-size: 4rem;
          font-weight: 500;
          letter-spacing: 1px;
        }
        .subtext {
          font-size: 0.9rem;
          margin-left: 0.5rem;
        }
      }

      .title {
        font-size: 1rem;
        font-weight: 400;
      }
    }
  }

  @media only screen and (max-device-width: 768px) {
    .counter {
      &__text {
        text-align: center;
        margin-bottom: 1rem;
      }
      grid-template-columns: 1fr;
    }
  }
</style>
