<template>
  <div v-if="transformIntro" class="section-padding">
    <div class="container default-padding">
      <el-row :gutter="30">
        <el-col :span="24" :lg="12" data-aos="fade-right">
          <div class="primary-heading">
            {{ transformIntro.title }}
          </div>
          <div class="caption">
            <!-- MANUFACTURERS OF DISTRIBUTION & POWER TRANSFORMERS UPTO 10 MVA OF 33
            KV CLASS -->
            {{ transformIntro.subtitle }}
          </div>
          <div class="paragraph" v-html="transformIntro.content" />
        </el-col>
        <el-col :spam="24" :lg="12">
          <img
            :src="require(`@/assets/images/defaults/transformer.jpg`)"
            alt="Transforming With Innovation"
            style="width: 100%; height: 100% "
            data-aos="fade-left"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    name: "TransformIntro",

    computed: {
      ...mapState({
        allContents: (state) => state.editableContent.editableContents.data,
      }),

      transformIntro() {
        return this.allContents && this.allContents[0];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .wrapper {
    padding: 5rem 0;
  }
</style>
