<template>
  <div class="test">
    <div class="test__item test__item--blue" data-aos="flip-left">
      <img
        :src="require('@/assets/images/defaults/clipboard-check.svg')"
        alt=""
        class="image"
      />
      <div class="title">
        Routine Test
      </div>
      <div class="description">
        The various tests as per relevant ISS are carried out on each an every
        Transformer to check the requirements as per specification / ISS.
      </div>
    </div>
    <div
      class="test__item test__item--yellow"
      data-aos="flip-left"
      data-aos-offset="100"
    >
      <img
        :src="require('@/assets/images/defaults/check2-circle.svg')"
        alt=""
        class="image"
      />
      <div class="title">
        Acceptance Test
      </div>
      <div class="description">
        The tests as per relevant ISS / Specification are carried out on the samples selected by the
        Inspecting Officer / representative of the purchaser from a lot.
      </div>
    </div>
    <div
      class="test__item test__item--green"
      data-aos="flip-left"
      data-aos-offset="200"
    >
      <img
        :src="require('@/assets/images/defaults/collection.svg')"
        alt=""
        class="image"
      />
      <div class="title">
        Type Test
      </div>
      <div class="description">
        The Tests are carried out to prove conformity with the specification / ISS / general quality
        and design on the sample sealed and selected by the Inspecting Office.
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Test",
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .test {
    display: grid;
    grid-template-columns: auto auto auto;

    &__item {
      padding: 5rem;
      text-align: center;

      .image {
        color: white;
        height: 100px;
      }

      .title {
        font-size: 1.5rem;
        font-weight: 500;
        margin: 1.5rem 0;
      }

      .description {
        //font-size: 1.25rem;
        color: white;
        line-height: 1.5rem;
      }

      &--yellow {
        background-color: #fbad06;
        color: white;
      }

      &--blue {
        background-color: #166495;
        color: white;
      }

      &--green {
        background-color: #06a490;
        color: white;
      }
    }
  }

  @media only screen and (max-device-width: 768px) {
    .test {
      grid-template-columns: auto;
    }
  }
</style>
