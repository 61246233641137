<template>
  <div v-show="albums.length" class="collage__wrapper">
    <div class="container default-padding">
      <div class="section-padding ">
        <div class="text-center" data-aos="fade-up">
          <div class="primary-heading mb-4">Recent Projects</div>
        </div>
        <el-row>
          <el-col
            v-for="(item, index) in albums"
            :key="item.id"
            :md="toggleCol(index)"
            :span="24"
            data-aos="zoom-in-up"
          >
            <div class="collage" @click="gotoAlbum(item)">
              <div class="collage__text">{{ item.name }}</div>
              <img
                :src="
                  item.first_media_preview.poster
                    ? item.first_media_preview.poster
                    : imagePlaceholder
                "
                alt="collage"
                class="collage__image"
              />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  import imagePlaceholder from "@/assets/images/defaults/media-placeholder.png";

  export default {
    name: "RecentProjects",

    data() {
      return {
        imagePlaceholder,
        albums: [],
      };
    },

    mounted() {
      this.getCollageImages();
    },

    methods: {
      toggleCol(index) {
        if (index % 10 === 0 || index % 10 === 6) {
          return 16;
        }
        return 8;
      },

      async getCollageImages() {
        this.albums = await this.$store.dispatch("album/getAllAlbums", {
          limit: 7,
          published: 1,
        });
      },

      gotoAlbum({ id, name }) {
        this.$router.push({
          name: "Project Detail",
          params: { projectId: id, title: name },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .collage__wrapper {
    background-color: $color-gray;
  }

  .collage {
    height: 250px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    margin: 0.2rem;

    &__text {
      position: absolute;
      inset: 0;
      display: grid;
      place-content: center;
      opacity: 1;
      z-index: 1;
      backdrop-filter: blur(2px);
      background-color: rgba(black, 0.3);
      transition: transform 200ms ease;
      color: white;
      padding: 0 2rem;
      text-align: center;
    }

    &__image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transform: scale(1.2);
      transition: transform 200ms ease;
    }

    &:hover &__text {
      opacity: 0;
    }

    &:hover &__image {
      transform: scale(1);
    }
  }
</style>
