<template>
  <div style="width: 100%">
    <banner-carousel />

    <counter />

    <transform-intro />

    <home-products />

    <home-about />

    <short-descriptions />

    <home-services />

    <tests />

    <horizontal-client-slider />

    <get-a-quote />

    <recent-projects />

    <!-- <quick-info /> -->

    <!-- Service Grid  -->
    <!-- <div class="services">
      <div class="container default-padding">
        <services-grid />
      </div>
    </div> -->

    <!-- Testimonial Slider -->
    <div class="testimonials">
      <div class="container default-padding">
        <testimonials />
      </div>
    </div>
  </div>
</template>

<script>
  import { labels } from "@/common";
  import BannerCarousel from "@/components/BannerCarousel.vue";
  import Testimonials from "@/components/ClientTestimonials";
  import ShortDescriptions from "../components/ShortDescriptions.vue";
  import TransformIntro from "../components/TransformIntro.vue";
  import GetAQuote from "../components/GetAQuote.vue";
  import HomeProducts from "../components/HomeProducts.vue";
  import HomeAbout from "../components/HomeAbout.vue";
  import Tests from "../components/Tests.vue";
  import HorizontalClientSlider from "../components/HorizontalClientSlider.vue";
  import RecentProjects from "../components/RecentProjects.vue";
  import Counter from "../components/Counter.vue";
  import HomeServices from "../components/HomeServices.vue";

  export default {
    name: "Home",

    components: {
      BannerCarousel,
      Testimonials,
      ShortDescriptions,
      TransformIntro,
      GetAQuote,
      HomeProducts,
      HomeAbout,
      Tests,
      HorizontalClientSlider,
      RecentProjects,
      Counter,
      HomeServices,
    },

    data() {
      return { labels };
    },
  };
</script>

<style lang="scss" scoped></style>
