<template>
  <div class="section-padding wrapper">
    <div class="container default-padding">
      <el-row :gutter="20">
        <el-col
          v-for="(description, index) in descriptions"
          :key="`${description}-${index}`"
          :span="24"
          :lg="6"
        >
          <border-animation-card
            class="my-4"
            data-aos="flip-left"
            :data-aos-offset="(index + 1) * 100"
            data-aos-duration="600"
          >
            <div class="description">
              <div>
                <img
                  :src="
                    require(`@/assets/images/defaults/${description.image}`)
                  "
                  alt=""
                  class="description__image"
                />
              </div>
              <div class="description__title">{{ description.title }}</div>
              <div class="description__content">{{ description.content }}</div>
            </div>
          </border-animation-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import BorderAnimationCard from "./BorderAnimationCard.vue";
  export default {
    name: "ShortDiscription",

    components: { BorderAnimationCard },

    data() {
      return {
        descriptions: [
          {
            title: "Our Vision",
            content:
              "We aspire to set new bench mark in the field of transformers industry by way of providing top quality distribution.",
            image: "vision.png",
          },
          {
            title: "Our Mission",
            content:
              "One of the leading transformer industries, We aspire to use a perfect bland of electical technology",
            image: "flag.png",
          },
          {
            title: "Our Values",
            content:
              "To supply the transformers as per the requirement of specifications of the Electric Utilities and Vendors",
            image: "badge.png",
          },
          {
            title: "Goals",
            content:
              "The Transformer industry is dedicated to maintain the complete satisfaction of our various Customers/Nigams ",
            image: "target.png",
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .wrapper {
    background: $background-color;
  }

  .description {
    min-height: 280px;
    padding: 2rem;
    text-align: center;

    // background: $color-gray;
    border-radius: 0.2rem;

    &__title {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    &__content {
      color: #555555;
      line-height: 1.5rem;
    }

    &__image {
      height: 60px;
      widht: 60px;
      margin-bottom: 1rem;
    }
  }

  .wrapper {
    padding: 5rem 0;
  }
</style>
