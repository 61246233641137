<template>
  <div v-if="allTestimonials" class="section-padding">
    <div class="text-center" data-aos="fade-left">
      <div class="primary-heading">
        {{ labels.LBL_TESTIMONIALS }}
      </div>
      <div class="caption">
        {{ labels.PH_TESTIMONIALS }}
      </div>
    </div>
    <br />
    <slider :slides="allTestimonials" sliderHeight="300" class="mt-6">
      <template v-slot:default="{ slide }">
        <div class="reviewer" data-aos="fade-left">
          <div class="reviewer__image">
            <img :src="slide.image_url" alt="Reviewer" />
          </div>
          <div class="reviewer__detail">
            <div class="review" v-html="slide.testimonial"></div>

            <div class="name">
              {{ slide.client_name }}
              <!-- <div class="position">{{ slide.position }}</div> -->
              <div class="position">({{ slide.client_business }})</div>
            </div>
          </div>
        </div>
      </template>
    </slider>
  </div>
</template>

<script>
  import { labels } from "@/common";
  import { mapState } from "vuex";
  import fakeTestimonials from "@/common/fakeData";
  import Slider from "@/components/DynamicSlider.vue";

  export default {
    name: "Testimonials",

    components: { Slider },

    data() {
      return {
        labels,
        fakeTestimonials,
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        testimonials: (state) => state.testimonial.testimonials,
      }),

      allTestimonials() {
        return this.testimonials && this.testimonials.data;
      },
    },

    created() {
      this.loadTestimonials();
    },

    methods: {
      loadTestimonials() {
        this.$store.dispatch("testimonial/getAllTestimonials");
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .reviewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    // background: red;

    padding: 2rem;
    margin-bottom: 2rem;

    &__image {
      flex-shrink: 0;
      height: 150px;
      width: 150px;
      border-radius: 50%;
      overflow: hidden;
      // margin-bottom: 1rem;

      img {
        height: 100%;
        width: 100%;
      }
    }

    &__detail {
      .name {
        margin: 0.75rem 0;
        font-size: 1.5rem;
        font-weight: 600;
      }

      .position {
        font-size: 1rem;
        font-weight: 400;
      }

      .review {
        font-size: 18px;
        color: #222;
        font-style: italic;
        line-height: 1.5em;
        @include line-clamp(5);
      }
    }
  }

  @media only screen and (max-device-width: 768px) {
    .reviewer {
      flex-direction: column;
      align-items: center;
      height: 100%;

      &__image {
        margin: 0;
        margin-bottom: 1rem;
      }

      &__detail {
        text-align: center;
      }
    }
  }
</style>
