<template>
  <div class="carousel-wrapper">
    <el-carousel height="80vh">
      <el-carousel-item v-for="banner in bannerList" :key="banner.id">
        <div class="carousel-overlay">
          <div class="carousel-text">
            {{ banner.title }}
          </div>
        </div>
        <el-image
          :src="banner.image_url"
          fit="cover"
          style="height: 100%; width: 100%"
          @click="gotoLink(banner.link)"
        >
        </el-image>
      </el-carousel-item>
    </el-carousel>

    <!-- <div class="carousel-actions">
      <el-button
        type="primary"
        class="btn-lg"
        @click="$router.push({ name: 'Contact Us' })"
      >
        {{ labels.MENU_CONTACT }}
      </el-button>
    </div> -->
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { labels } from "@/common";

  export default {
    name: "Carousel",

    data() {
      return {
        labels,
      };
    },

    computed: {
      ...mapState({ banners: (state) => state.banner.banners }),

      bannerList() {
        if (this.banners) return this.banners.data;
        return [];
      },
    },

    mounted() {
      this.loadBanners();
    },

    methods: {
      loadBanners() {
        this.$store.dispatch("banner/getAllBanners", {
          limit: 20,
          published: 1,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main";

  .el-carousel {
    position: relative;
  }

  .carousel-wrapper {
    position: relative;
  }

  .carousel-overlay {
    position: absolute;
    inset: 0;
    background: rgba(#000, 0.4);
    z-index: 5;
  }

  .carousel-text {
    position: absolute;
    top: 50%;
    left: 5%;
    color: white;
    font-size: clamp(1rem, 8.333vw, 3.75rem);
    font-weight: 500;
    transform: translateY(-50%);
    text-transform: uppercase;
    line-height: 4.5rem;
    width: 60%;
  }

  .carousel-actions {
    position: absolute;
    z-index: 5;
    bottom: 10%;
    right: 5%;
  }

  // .btn-lg {
  //   padding: 1rem 1.25rem;
  //   font-size: 1.125rem;
  //   text-transform: uppercase;
  // }
</style>
